import API from "./api";

const Harms = () => window.map_data.Harms.map( harm => harm.Term );

export default class extends HTMLElement {
    fieldMappings = {
        "Title": { api_key: "new_title" },
        "Authors": { api_key: "authors" },
        "Publication": { api_key: "publication" },
        "External link": { api_key: "url", type: "link" },
        "Resource Type": { api_key: "type" },
    }

    #originalTitle = ""

    connectedCallback () {
        this.#originalTitle = this.attributes.original_title.value;
        
        if (API.isLoggedIn) {
            this.innerHTML += `
                 <button id="propose-changes">Propose changes</button>
                <div class="edit-form">
                    <input type="text" id="reasoning" api-key="reasoning" placeholder="Describe your reasoning. Why is this change necessary?"></input>
                </div>
                <div class="edit-form buttons">
                    <button id="revert">Cancel</button>
                    <button id="save">Propose</button>
                </div>
                <p class="thanks"> </p>
                <p class="thanks">
                    Thank you for your proposal!
                </p>
            `;

            this.setAttribute("edit-mode", "none");

            const proposeButton = this.querySelector("button#propose-changes");
            proposeButton.addEventListener("click", async (e) => {
                this.setAttribute("edit-mode", "editing");
                this.prepareEditForm();
            }); 

            const revertButton = this.querySelector("button#revert");
            revertButton.addEventListener("click", async (e) => {
                this.setAttribute("edit-mode", "none");
                this.revertEditForm();
            }); 

            const saveButton = this.querySelector("button#save");
            saveButton.addEventListener("click", async (e) => {
                this.submitForm();
                this.setAttribute("edit-mode", "complete");
            }); 
        }
    }

    prepareEditForm() {
        const fields = this.querySelectorAll(".harm__related_resources__dialog__item");
        fields.forEach( field => {
            const dt = field.querySelector("dt");
            const dd = field.querySelector("dd");
            const mapping = this.fieldMappings[dt.innerHTML];

            if (!dt || !dd || !mapping) {
                return;
            }

            dd.setAttribute("api-key", mapping.api_key);
            dd.setAttribute("mapping-key", dt.innerHTML);

            dd.innerHTML = `<input type="text" api-key="${mapping.api_key}" value="${this.getDdMappedValue(dd, mapping)}"></input>`;
        });

        this.appendHarmSelect(); 
    }

    appendHarmSelect() {
        const harmsField = this.querySelector("[role='harms-display']");
        const harmsFieldDd = harmsField.querySelector("dd");

        const selectList = document.createElement("select");
        selectList.role = "new-harm-relation";

        const option = document.createElement("option");
        option.value = "";
        option.text = "New harm relation";
        selectList.appendChild(option);

        Harms().forEach(harm => {
            const option = document.createElement("option");
            option.value = harm;
            option.text = harm;
            selectList.appendChild(option);
        })

        harmsFieldDd.appendChild(selectList);
    }

    revertEditForm() {
        const fields = this.querySelectorAll("dd[api-key]");
        fields.forEach( dd => {
            const input = dd.querySelector("input");
            const mapping = this.fieldMappings[dd.attributes["mapping-key"].value];
            dd.innerHTML = this.getDdUnpappedValue(input.value, mapping);
        });

        this.querySelectorAll("[role=new-harm-relation]").forEach(e => e.remove());
    }

    async submitForm() {
        const values = {
            old_title: this.#originalTitle,
        };

        this.querySelectorAll("input[api-key]").forEach( field => {
            values[field.attributes["api-key"].value] = field.value;
        });

        await API.proposeResourceChange(values);
        await Promise.all(
            Array.from(
                this.querySelectorAll("[role=new-harm-relation]")
            ).filter(
                ({value}) => !!value
            ).map(
                ({value}) => value
            ).map(
                harm => API.proposeNewResourceHarm(
                    this.#originalTitle, 
                    harm, 
                    this.querySelector("[api-key=reasoning]")?.value,
                )
            ),
        )

        this.revertEditForm();
    }

    getDdMappedValue(dd, mapping) {
        if (mapping.type === "link") {
            const a = dd.querySelector("a");
            return a.attributes.href.value;
        }

		if (dd.querySelector("span.none_value")) {
			return "";
		}

        return dd.innerHTML;
    }

    getDdUnpappedValue(value, mapping) {
        if (mapping.type === "link") {
            return `<a href=${value}>${value}</a>`
        }
        return value;
    }
}
