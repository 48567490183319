import { publish } from "./pubsub";

// This should be set in the envrionment as API_URL
export const URL = "ENV_API_URL";

export const Events = {

    Login: "login",
    Logout: "logout",
};

const addLocalEventListener = (el, eventName, eventHandler, selector) => {
    if (selector) {
        const wrappedHandler = (e) => {
            if (e.target) {
                const el = e.target.closest(selector);
                if (el) {
                    eventHandler(e, el);
                }
            }
        };
        el.addEventListener(eventName, wrappedHandler);
        return wrappedHandler;
    } else if (el.addEventListener) {
        el.addEventListener(eventName, eventHandler);
        return eventHandler;
    }
};

function parseJwt (token) {
    var base64Url = token.split(".")[1];
    var base64 = base64Url.replace(/-/g, "+").replace(/_/g, "/");
    var jsonPayload = decodeURIComponent(atob(base64).split("").map(function (c) {
        return "%" + ("00" + c.charCodeAt(0).toString(16)).slice(-2);
    }).join(""));

    return JSON.parse(jsonPayload);
}

function formData (input) {
    const form_data = new FormData();

    for ( var key in input ) {
        form_data.append(key, input[key]);
    }
    return form_data;
}

export class API {
    #authToken
    #refreshToken

    constructor () {
        addLocalEventListener(document, "login", (e, details) => { 
            const event = new CustomEvent(Events.Login, {detail: details});
            dispatchEvent(event);
        });

        addLocalEventListener(document, "logout", (e, details) => { 
            const event = new CustomEvent(Events.Logout, {detail: details, composed: true, bubble: true});
            dispatchEvent(event);
        });

        this.dispatchUserStateEvents();  
    }

    /*
     * This is a bit of a hack. Scrape the URL for oauth callback signtures and
     * use them to populate the login state.
     */
    async handleAuth () {
        const urlParams = new URLSearchParams(window.location.search);
        const callback = urlParams.get("callback");
        const code = urlParams.get("code");

        if (!callback && !code) {
            return;
        }

        try {
            const result = await this.getJSON("/callback?code=" + code);
            this.authToken = result.token;
            this.refreshToken = result.refresh_token;
            window.history.pushState({}, document.title, window.location.pathname);
        } catch (e) {
            console.log(e);
        }
    }

    tokenExpired () {
        if (!this.user) {
            return false;
        }

        const delta = this.user.exp - (Date.now() / 1000 | 0);
        return delta <= 0;
    }

    async getJSON (url) {
        if (this.tokenExpired()) {
            await this.rotateToken();
        }

        const response = await fetch(URL+url, {
            headers: { 
                ...(!!this.authToken && {"Authorization": this.authToken}),
            },
        });

        const body = await response.json();
        this.handleAPIError(response, body);
        return await body;
    }

	async post(url, data) {
        if (this.tokenExpired()) {
            await this.rotateToken();
        }

		return fetch(URL+url, {
			method: "POST",
			headers: {
				...(!!this.authToken && {"Authorization": this.authToken}),
			},
			body: JSON.stringify(data),
		});
	}

	async put(url, data) {
        if (this.tokenExpired()) {
            await this.rotateToken();
        }

		return fetch(URL+url, {
			method: "PUT",
			headers: {
				...(!!this.authToken && {"Authorization": this.authToken}),
			},
			body: JSON.stringify(data),
		});
	}

	async delete(url, data) {
        if (this.tokenExpired()) {
            await this.rotateToken();
        }

		return fetch(URL+url, {
			method: "DELETE",
			headers: {
				...(!!this.authToken && {"Authorization": this.authToken}),
			},
			body: JSON.stringify(data),
		});
	}

    async rotateToken () {
        const user = this.user;
        if (!user) {
            return;
        }

        const response = await fetch(
            URL+"/refresh",
            {
                method: "POST", 
                body: formData({
                    "refresh_token": this.refreshToken,
                }),
            });

        const json = await response.json();
        this.handleAPIError(response, json);
        const body = await json;

        this.authToken = body.token;
    }

    async getUsersList () {
        return this.getJSON("/users");
    }

    async makeAdmin(id) {
        return this.post(`/admin/users/${id}`);
    }

    async deleteUser(id) {
        return this.delete(`/users/${id}`);
    }
    async proposeNewResource(title, link, authors, publication, resource_type, harms, reasoning) {
        return this.post(`/cr/resource`, {title, link, authors, publication, resource_type, harms, reasoning});
    }

    async proposeNewHarm(term, description, reasoning) {
        return this.post(`/cr/harm`, {term, description, reasoning});
    }

    async proposeNewPositive(term, description, reasoning) {
        return this.post(`/cr/positive`, {term, description, reasoning});
    }

    async proposeNewChallenge(title, reasoning) {
        return this.post(`/cr/challenge`, {title, reasoning});
    }

    async proposeResourceChange(fields) {
        return this.put("/cr/resource", fields);
    }

    async proposeNewResourceHarm(title, harm, reasoning) {
        return this.post("/cr/resource/relations", {title, harm, reasoning});
    }

    async proposeHarmChange(old_term, new_term, description, reasoning) {
        return this.put("/cr/harm", {old_term, new_term, description, reasoning});
    }

    async proposeHarmRelationshipToPositive(term, positive, reasoning) {
        return this.post("/cr/harm/relations", {term, positive, reasoning});
    }

    async proposePositiveChange(old_term, new_term, description, reasoning) {
        return this.put("/cr/positive", {old_term, new_term, description, reasoning});
    }

    async proposeChallengeChange(old_title, new_title, reasoning) {
        return this.put("/cr/challenge", {old_title, new_title, reasoning});
    }

    async proposeChallengeRelationshipToHarm(title, harm, reasoning) {
        return this.post("/cr/challenge/relations", {title, harm, reasoning});
    }

    handleAPIError (response, body) {
        if (!response.ok) {
            const message = `An error has occured: ${response.status}`;
            throw new Error(body && body.error || message, { cause: response.status });
        }
    }

    set authToken (token) {
        const newVal = token || null;
        const changed = newVal !== this.authToken;

        if (token) {
            localStorage.setItem("auth_token", token);
            this.#authToken = token;
        } else {
            localStorage.removeItem("auth_token");
            this.#authToken = undefined;
        }

        if (changed) {
            this.dispatchUserStateEvents();  
        }
    }

    get authToken () {
        return this.#authToken || localStorage.getItem("auth_token");
    }

    get isLoggedIn () {
        return !!this.authToken;
    }

    set refreshToken (token) {
        if (token) {
            localStorage.setItem("refresh_token", token);
            this.#refreshToken = token;
        } else {
            localStorage.removeItem("refresh_token");
            this.#refreshToken = undefined;
        }
    }

    get refreshToken () {
        return this.#refreshToken || localStorage.getItem("refresh_token");
    }

    get user () {
        if (!this.isLoggedIn) {
            return undefined;
        }

        try {
            return parseJwt(this.authToken);
        } catch (e) {
            console.error(e);
            return undefined;
        }
    }

    dispatchUserStateEvents () {
        if (!this.isLoggedIn) {
            publish(document, "logout");
            return;
        }

        publish(document, "login");
    }
}

export default new API();
